import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import * as Keycloak from 'keycloak-js';

@Injectable()
export class AuthenticationService {

  constructor(private keycloakService: KeycloakService) {
  }

  get username(): string {
    return this.keycloakService.getUsername();
  }

  getUserRoles() {
    return this.keycloakService.getUserRoles();
  }

  getUserProfile() {
    return this.keycloakService.loadUserProfile();
  };

  login(options?: Keycloak.KeycloakLoginOptions) {
    return this.keycloakService.login(options);
  }

  getAccessToken() {
    return this.keycloakService.getToken();
  }

  isLoggedIn(): boolean {
    return this.keycloakService.isLoggedIn();
  }

  async logout(redirectUri: string) {
    await this.keycloakService.logout(redirectUri);
  }
}
