import { NgTemplateOutlet } from '@angular/common';
import { RouterLink, RouterOutlet } from '@angular/router';
import { Component, ContentChild, TemplateRef } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Ripple } from 'primeng/ripple';
import { MenuModule } from 'primeng/menu';
import { BadgeModule } from 'primeng/badge';
import { ToastModule } from 'primeng/toast';
import { ChipsModule } from 'primeng/chips';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { StyleClassModule } from 'primeng/styleclass';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AuthenticationService } from '@adlatus-gui/domain/authentication';
import { getNameInitials } from '../../util/name.util';

@Component({
  selector: 'lib-main-layout',
  standalone: true,
  providers: [AuthenticationService],
  imports: [RouterOutlet, ButtonModule, Ripple, StyleClassModule, BadgeModule, RouterLink, AvatarModule, NgTemplateOutlet, ChipsModule, MenuModule, OverlayPanelModule, ToastModule],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss'
})
export class MainLayoutComponent {
  protected readonly getNameInitials = getNameInitials;
  @ContentChild('navigationTemplate', { static: true }) navigationTemplate!: TemplateRef<never>;

  goBackToButtonUrl = false;

  constructor(
    private authenticationService: AuthenticationService) {
  }

  userMenuItems: MenuItem[] = [
    {
      label: 'Logout',
      icon: 'pi pi-sign-out',
      styleClass: 'text-red-200',
      iconClass: 'text-red-500',
      command: () => this.logout()
    },
    {
      label: 'Information',
      icon: 'pi pi-info-circle',
      command: () => this.information()
    }
  ];

  get fullName() {
    return localStorage.getItem('fullName');
  };

  get title() {
    return document.title;
  }

  logout() {
    this.authenticationService.logout(window.location.href)
      .then(() => console.info('Successfully logged out'));
  }

  information() {
    console.info('Logged in as: ', this.fullName);
  }
}
