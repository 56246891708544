import { KeycloakEnvOptions } from '../../auth.provider';

const baseHref = document.getElementsByTagName('base')[0].getAttribute('href');

export const getKeycloakOptions = (keycloakEnvOptions: KeycloakEnvOptions) => {
  return {
    config: {
      url: keycloakEnvOptions.URL,
      realm: keycloakEnvOptions.REALM,
      clientId: keycloakEnvOptions.CLIENT_ID
    },
    loadUserProfileAtStartUp: true,
    initOptions: {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri:
        window.location.origin + baseHref + 'silent-check-sso.html',
      checkLoginIframe: false,
      redirectUri: keycloakEnvOptions.REDIRECT_URI
    }
  };
};
